import React, { useEffect, useState } from "react";
import { SubListItem } from "./styled";

import ScanSession from "./ScanSession";
import { IScanSession } from "../../api/Interfaces";
import { Timeout } from "../public/Login";

const API_URL: string | undefined = process.env.REACT_APP_API_URL;

const ScanSessions = ({ scanSessions }: { scanSessions: Array<IScanSession> }) => {
    return (
        <div>
            <SubListItem id="example-collapse-text">
                {scanSessions.map((scan, idx) => (
                    <div key={idx}>
                        <ScanSession {...scan} scan_index={idx + 1} />
                    </div>
                ))}
            </SubListItem>
        </div >
    )

}

export default ScanSessions;
