import * as React from "react";

import { FeatureCollection, Point, Geometry, GeoJsonProperties } from "geojson";

type Action =
  | {
      type: "INIT";
      payload: {
        data: FeatureCollection<Geometry, GeoJsonProperties> | undefined;
        override_start_id: number | null;
      };
    }
  | { type: "UPDATE_FROM_PANORAMA"; payload: { id: number } }
  | { type: "UPDATE_FROM_MAP"; payload: { id_map: number; id_pano: number } }
  | { type: "UPDATE_FROM_SEARCHBOX"; payload: { id: number } }
  | { type: "UPDATE_FROM_POINTCLOUD"; payload: { id: number } }
  | { type: "TOGGLE_MAP" };

type Dispatch = (action: Action) => void;

type State = {
  data?: FeatureCollection<Geometry, GeoJsonProperties> | undefined;
  mapId: number;
  panoId: number;
  dataId: number;
  updater: string;
  mapExpanded: boolean;
};

type ViewerProviderProps = { children: React.ReactNode };

const initialState: State = {
  data: { type: "FeatureCollection", features: [] },
  mapId: 0,
  panoId: 1,
  dataId: 0,
  updater: "panorama",
  mapExpanded: false,
};

const ViewerStateContext = React.createContext<
  { viewerState: State; setViewerState: Dispatch } | undefined
>(undefined);

function viewerReducer(state: State, action: Action) {
  switch (action.type) {
    case "TOGGLE_MAP":
      return {
        ...state,
        mapExpanded: !state.mapExpanded,
      };
    case "UPDATE_FROM_MAP":
      return {
        ...state,
        mapId: action.payload.id_map,
        panoId: action.payload.id_pano,
        dataId: action.payload.id_map,
        updater: "map",
      };
    case "UPDATE_FROM_PANORAMA":
      return {
        ...state,
        mapId: action.payload.id - 1,
        panoId: action.payload.id,
        dataId: action.payload.id - 1,
        updater: "panorama",
      };
    case "UPDATE_FROM_SEARCHBOX":
      return {
        ...state,
        mapId: action.payload.id,
        panoId: action.payload.id + 1,
        dataId: action.payload.id,
        updater: "searchbox",
      };
    case "UPDATE_FROM_POINTCLOUD":
      return {
        ...state,
        mapId: action.payload.id - 1,
        panoId: action.payload.id,
        dataId: action.payload.id,
        updater: "pointcloud",
      };
    case "INIT":
      if (action.payload.override_start_id) {
        return {
          ...state,
          data: action.payload.data,
          mapId: action.payload.override_start_id,
          panoId: action.payload.override_start_id + 1,
          dataId: action.payload.override_start_id,
          updater: "init",
        };
      }
      return {
        ...state,
        data: action.payload.data,
        mapId: 0,
        panoId: 1,
        dataId: 0,
        updater: "init",
      };
    default:
      return state;
  }
}

function ViewerProvider({ children }: ViewerProviderProps) {
  const [viewerState, setViewerState] = React.useReducer(
    viewerReducer,
    initialState
  );
  // NOTE: you *might* need to memoize this value Learn more in http://kcd.im/optimize-context
  const value = React.useMemo(
    () => ({ viewerState, setViewerState }),
    [viewerState, setViewerState]
  ); // { viewerState, setViewerState };
  return (
    <ViewerStateContext.Provider value={value}>
      {children}
    </ViewerStateContext.Provider>
  );
}

function useViewer() {
  const context = React.useContext(ViewerStateContext);
  if (context === undefined) {
    throw new Error("useViewer must be used within a ViewerProvider");
  }
  return context;
}

export { ViewerProvider, useViewer };
