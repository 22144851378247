import React, { useState, useEffect } from "react";
import "../../assets/css/SearchBox.css";
import searchIcon from "../../assets/svg/IkonSearch.svg";
import { useViewer } from "../../viewerContext";

const SearchBoxComponent = ({ onSearch }) => {
  const [availableBandels, setAvailableBandels] = useState([]);
  const [availableKms, setAvailableKms] = useState([]);
  const [availableMeters, setAvailableMeters] = useState([]);
  const [currentId, setCurrentId] = useState(null);

  const { viewerState, setViewerState } = useViewer();

  // Values from sessionStorage
  const [bdl, setBdl] = useState(() => {
    return sessionStorage.getItem("bdl") || "";
  });
  const [km, setKm] = useState(() => {
    return sessionStorage.getItem("km") || "";
  });
  const [m, setM] = useState(() => {
    return sessionStorage.getItem("m") || "";
  });

  // Function to iterate over the data and create a JSON object
  const processViewerData = () => {
    const features = viewerState.data?.features || [];
    const processedData = {};

    features.forEach((item, index) => {
      const attributes = item.properties.attributes;
      if (attributes) {
        const bandel = attributes[6];
        const kilometer = Math.round(attributes[4]);
        const meter = Math.round(parseFloat(attributes[5].replace("+", "")));

        if (!processedData[bandel]) {
          processedData[bandel] = {};
        }
        if (!processedData[bandel][kilometer]) {
          processedData[bandel][kilometer] = [];
        }
        processedData[bandel][kilometer].push({ meter, index });
      }
    });

    return processedData;
  };

  useEffect(() => {
    const processedData = processViewerData();
    setAvailableBandels(Object.keys(processedData));

    if (bdl && processedData[bdl]) {
      setAvailableKms(Object.keys(processedData[bdl]));
    }
    if (km && processedData[bdl] && processedData[bdl][km]) {
      setAvailableMeters(processedData[bdl][km]);
    }
    window.addEventListener("beforeunload", () => {
      // Adds a listener, which is triggered when the user tries to close or refresh the page
      sessionStorage.clear(); // Clears sessionStorage when the page is to be reloaded or closed
    });
  }, [viewerState, bdl, km]);

  // When the user selects a bandel, update the available kilometers
  const handleBdlChange = (e) => {
    const selectedBdl = e.target.value;
    setBdl(selectedBdl);
    sessionStorage.setItem("bdl", selectedBdl); // Save bdl to sessionStorage

    const processedData = processViewerData();
    if (processedData[selectedBdl]) {
      setAvailableKms(Object.keys(processedData[selectedBdl]));
    } else {
      setAvailableKms([]);
    }
  };

  // When the user selects a km, update the available meter
  const handleKmChange = (e) => {
    const selectedKm = e.target.value;
    setKm(selectedKm);
    sessionStorage.setItem("km", selectedKm); // Save to km sessionStorage

    const processedData = processViewerData();
    if (processedData[bdl] && processedData[bdl][selectedKm]) {
      setAvailableMeters(processedData[bdl][selectedKm]);
    } else {
      setAvailableMeters([]);
    }
  };

  const handleMChange = (e) => {
    const selectedMeter = e.target.value;
    setM(selectedMeter);
    sessionStorage.setItem("m", selectedMeter); // Save to m sessionStorage
  };

  const handleSearch = async (e) => {
    e.preventDefault(); // Prevents the page from reloading

    // Find the object that matches the selected meter value
    const selectedMeter = availableMeters.find(
      ({ meter }) => meter === parseInt(m)
    );

    if (selectedMeter) {
      setCurrentId(selectedMeter.index); // Set current_id to the index of the selected meter
    }

    setViewerState({
      type: "UPDATE_FROM_SEARCHBOX",
      payload: { id: selectedMeter.index }, // Pass the current index to viewerState
    });
  };

  return (
    <form className="search-box" onSubmit={handleSearch}>
      {/* Bandel dropdown */}
      <select value={bdl} onChange={handleBdlChange}>
        <option value="">Section</option>
        {availableBandels.map((bandel) => (
          <option key={bandel} value={bandel}>
            {bandel}
          </option>
        ))}
      </select>

      {/* Kilometer dropdown */}
      <select value={km} onChange={handleKmChange} disabled={!bdl}>
        <option value="">{bdl ? "km" : ""}</option>
        {availableKms.map((km) => (
          <option key={km} value={km}>
            {km}
          </option>
        ))}
      </select>

      {/* Meter dropdown */}
      <select value={m} onChange={handleMChange} disabled={!km}>
        <option value="">{km ? "m" : ""}</option>
        {availableMeters.map(({ meter, index }) => (
          <option key={index} value={meter}>
            {meter}
          </option>
        ))}
      </select>

      <button className="search-btn" type="submit">
        <img src={searchIcon} alt="Search" className="search" />
      </button>
    </form>
  );
};

export default SearchBoxComponent;
