import { useEffect } from "react";
import { ISensorScan } from "../../api/Interfaces";
import { Img } from "./styled";
import CameraIcon from "../../assets/svg/CameraIcon.svg";
import CameraIconDark from "../../assets/svg/CameraIconDark.svg";
import Icon360 from "../../assets/svg/Icon360.svg";
import Icon360Dark from "../../assets/svg/Icon360Dark.svg";
import Dark360 from "../../assets/svg/Dark360.svg";
import Light360 from "../../assets/svg/Light360.svg";
import { SensorType } from "../../api/Enums";


const PanoramaField = (allowed: boolean) => {
    const icon = allowed ? Light360 : Dark360;
    useEffect(() => { }, []);
    return (
        <div>
            <Img css={`
            display: block;
            margin-top: 0px;
            `}
                alt="360Icon"
                src={icon}
                className="d-inline-block align-top"
            />
        </div>
    )
}

const CameraField = (allowed: boolean) => {
    const icon = allowed ? CameraIcon : CameraIconDark;
    const marginTop = allowed ? "6px": "3px";
    return (
        <div>
            <Img css={`
            display: block;
            margin-top: ${marginTop};
            `}
                alt="360Icon"
                src={icon}
                className="d-inline-block align-top"
            />
        </div>
    )
}

const LidarField = (allowed: boolean) => {
    const icon = allowed ? Icon360 : Icon360Dark;
    return (
        <div>
            <Img css={`
            display: block;
            margin-top: 3px;
            `}
                alt="360Icon"
                src={icon}
                className="d-inline-block align-top"
            />
        </div>
    )
}

const SensorScan = (sensorScan: ISensorScan) => {
    var icon;
    switch(sensorScan.type) {
        case SensorType.panoramic_camera:
            icon = PanoramaField(sensorScan.allowed);
            break;
        case SensorType.camera:
            icon = CameraField(sensorScan.allowed);
            break;
        case SensorType.lidar:
            icon = LidarField(sensorScan.allowed);
            break;
    }

    return (
        <div>
            {icon}
        </div>
    )
}

export default SensorScan;