import React, { useEffect, useRef, useState } from "react";
import MapComponent from "../../components/MapComponent/MapComponent";
import SearchBoxComponent from "../../components/SearchBoxComponent/SearchBoxComponent";
import { Img, StyledDiv, StyledMapButton } from "./styled";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useViewer } from "../../viewerContext";
import "../../assets/css/Loader.css";
import "../../assets/css/Viewer.css";
/*** SVGs  ***/
import logo from "../../assets/svg/ViewerLogo.svg";
import Minimize from "../../assets/svg/minimizeMap.svg";
import Maximize from "../../assets/svg/maximizeMap.svg";
import Lidar from "../../assets/svg/ViewLidar.svg";
import Camera360 from "../../assets/svg/ViewCamera360.svg";
import LidarActive from "../../assets/svg/ViewLidarActive.svg";
import Camera360Active from "../../assets/svg/ViewCamera360Active.svg";
import closeIcon from "../../assets/svg/close.svg";
import ViewerBar from "../../components/ViewerBar/ViewerBar";
import { DateTime } from "luxon";
import { IIndexedScanSession } from "../../api/Interfaces";
import axios from "axios";
import { Background } from "../public/styled";
import PanoramaViewer from "../../components/PanoramaComponent/PanoramaComponent";
import PointCloudViewer from "../../components/PointCloudComponent/PointCloudComponent";
import { Timeout } from "../public/Login";

/*** SVGs end ***/

const API_URL: string | undefined = process.env.REACT_APP_API_URL;
const CDN_URL: string | undefined = process.env.REACT_APP_CDN_URL;

export default function MainViewer() {
  /***Panorama ***/
  const navigate = useNavigate();
  const location: any = useLocation();

  const [error, setError] = useState<any>(null);
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const { viewerState, setViewerState } = useViewer();
  const [sensorScanId, setSensorScanId] = useState<number>();
  const [pointCloudCount, setPointCloudCount] = useState<number>();

  const [activeView, setActiveView] = useState("panorama");
  const [view, setView] = useState("panorama");
  const scanSessionObject: IIndexedScanSession = location.state.scanSession;
  const scanSessionDate: string = DateTime.fromISO(
    scanSessionObject.date.toString()
  ).toFormat("dd LLLL yyyy");
  const scanSessionFolder: string = DateTime.fromISO(
    scanSessionObject.date.toString()
  ).toFormat("yyyyLLdd_HHmmss");

  const scanSessionCRS: string = location.state.crs;
  const toSensorType: string = location.state.toSensorType;
  const potreeChildRef = useRef<any>();

  // const [hasLidar, setHasLidar] = useState<boolean>();
  // const [has360Camera, setHas360Camera] = useState<boolean>();
  // const [hasCamera, setHasCamera] = useState<boolean>();

  const [mapCSS, setMapCSS] = useState({
    width: "260px",
    height: "150px",
    left: "50px",
    bottom: "50px",
  });
  const [mapButtonCSS, setMapButtonCSS] = useState({
    width: "25",
    left: "60px",
    bottom: "60px",
  });
  const [expandedMap, setExpandedMap] = useState<boolean>(false);
  const [mapIcon, setMapIcon] = useState(Maximize);

  // To do: disable unavailable scansens button
  // const getAllowedSensorScans = () => {
  //   let sen = [];
  //   for (
  //     let index = 0;
  //     index < scanSessionObject.sensor_scans.length;
  //     index++
  //   ) {
  //     if (
  //       scanSessionObject.sensor_scans[index].type === "LIDAR" &&
  //       scanSessionObject.sensor_scans[index].allowed
  //     ) {
  //       setHasLidar(true);
  //     }
  //     if (
  //       scanSessionObject.sensor_scans[index].type === "360_CAMERA" &&
  //       scanSessionObject.sensor_scans[index].allowed
  //     ) {
  //       setHas360Camera(true);
  //     }
  //     if (
  //       scanSessionObject.sensor_scans[index].type === "CAMERA" &&
  //       scanSessionObject.sensor_scans[index].allowed
  //     ) {
  //       setHasCamera(true);
  //     }
  //   }
  // };

  /************* */
  const handleSwitchPC2Pano = async (updatedPointCloudLoc: any) => {
    axios
      .get(
        `${API_URL}/scansessions/${scanSessionObject.id}/${scanSessionCRS}?center=${updatedPointCloudLoc.x}&center=${updatedPointCloudLoc.y}&to_sensor_type=${toSensorType}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const newCameraFrame = response.data;

        if (newCameraFrame[0] !== viewerState.dataId + 1) {
          setViewerState({
            type: "UPDATE_FROM_POINTCLOUD",
            payload: { id: newCameraFrame[0] },
          });
        }
      });
  };

  /***  */
  useEffect(() => {
    fetchData(scanSessionObject.id).catch((error) => {
      setError(error);
    });
  }, []);

  const fetchData = async function (id: number) {
    const response = await fetch(`${API_URL}/scansessions/${id}`, {
      headers: {
        "Content-Type": "application/json",
        "sec-fetch-mode": "no-cors",
      },
      method: "GET",
      credentials: "include",
      signal: Timeout(120).signal,
    });
    const respData = await response.json();
    setSensorScanId(respData.panorama_sensor_scan_id);
    delete respData.panorama_sensor_scan_id;
    setPointCloudCount(respData.point_cloud_count);
    delete respData.point_cloud_count;
    if (scanSessionObject.id === 1) {
      setViewerState({
        type: "INIT",
        payload: { data: respData, override_start_id: 588 }
      })
    }
    else if (scanSessionObject.id === 4) {
      setViewerState({
        type: "INIT",
        payload: { data: respData, override_start_id: 604 }
      })
    }
    else if (scanSessionObject.id === 6) {
      setViewerState({
        type: "INIT",
        payload: { data: respData, override_start_id: 12054 }
      })
    }
    else if (scanSessionObject.id === 7) {
      setViewerState({
        type: "INIT",
        payload: { data: respData, override_start_id: 5246 }
      })
    }
    else if (scanSessionObject.id === 9) {
      setViewerState({
        type: "INIT",
        payload: { data: respData, override_start_id: 9927 }
      })
    }
    else if (scanSessionObject.id === 17) {
      setViewerState({
        type: "INIT",
        payload: { data: respData, override_start_id: 4256 }
      })
    }
    else if (scanSessionObject.id === 20) {
      setViewerState({
        type: "INIT",
        payload: { data: respData, override_start_id: 3028 }
      })
    }
    else if (scanSessionObject.id === 21) {
      setViewerState({
        type: "INIT",
        payload: { data: respData, override_start_id: 68 }
      })
    }
    else if (scanSessionObject.id === 22) {
      setViewerState({
        type: "INIT",
        payload: { data: respData, override_start_id: 15980 }
      })
    }
    else {
      setViewerState({
        type: "INIT",
        payload: { data: respData, override_start_id: null }
      })
    }
    setIsDataReady(true);
  };

  const SwitchNav = () => {
    return (
      <Navbar className="switch-nav" variant="dark">
        <Container>
          <Nav className="me-auto">
            <StyledDiv
              onClick={(e) => {
                setActiveView("panorama");
                setView("panorama");
                if (activeView === "lidar") {
                  handleSwitchPC2Pano(potreeChildRef.current.getAlert());
                }
              }}
              css="float: right;
                              flex: 50%;
                              padding: 10px 10px 10px 0px;
                              width: auto;"
            >
              {activeView === "panorama" && <Img src={Camera360Active} />}
              {activeView !== "panorama" && <Img src={Camera360} />}
            </StyledDiv>
            <StyledDiv
              onClick={(e) => {
                setActiveView("lidar");
                setView("lidar");
              }}
              css="float: right;
                              flex: 50%;
                              padding: 10px 10px 10px 10px;
                              width: auto;"
            >
              {activeView === "lidar" && <Img src={LidarActive} />}
              {activeView !== "lidar" && <Img src={Lidar} />}
            </StyledDiv>
            <StyledDiv
              css="float: right;
                              flex: 50%;
                              padding: 10px 10px 10px 10px;
                              width: auto;"
            >
              <Link to="/">
                <Img src={closeIcon} />
              </Link>
            </StyledDiv>
          </Nav>
        </Container>
      </Navbar>
    );
  };

  const ViewerNav = () => {
    return (
      <StyledDiv
        css="position: absolute;
                            display: flex;
                            justify-content: space-between;
                            padding-right: 15%;
                            min-width: 100%;
                        "
      >
        <Img css="padding: 10px; z-index:1;" src={logo} />
        <ViewerBar ssd={scanSessionDate} />
        <SwitchNav />
      </StyledDiv>
    );
  };

  const expandMap = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.preventDefault();
    setExpandedMap(true);
    setMapIcon(Minimize);
    setMapCSS({ width: "25%", height: "60vh", left: "50px", bottom: "50px" });
    setMapButtonCSS({
      width: "25",
      left: "60px",
      bottom: "60px",
    });
  };

  const collapseMap = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.preventDefault();
    setExpandedMap(false);
    setMapIcon(Minimize);
    setMapCSS({
      width: "260px",
      height: "150px",
      left: "50px",
      bottom: "50px",
    });
    setMapButtonCSS({ width: "25", left: "60px", bottom: "60px" });
  };

  const MiniMapBox = () => {
    return (
      <StyledDiv
        css={`
          background-color: green;
          background: rgba(135, 206, 235, 0.2);
          position: fixed;
          width: ${mapCSS.width};
          left: ${mapCSS.left};
          bottom: ${mapCSS.bottom};
          z-index: 2;
          border: solid #A9a9a9 1px;
          border-radius: 15px;
          overflow: hidden;
        `}
      >
         {expandedMap && ( // This means that the search function is only displayed when the MiniMapBox map is expended/large
          <SearchBoxComponent
            onSearch={(searchParams: { bdl: string; km: string; m: string }) =>
              console.log(searchParams)
            }
          />
        )}
        <MapComponent map_style={{ height: mapCSS.height }} />
      </StyledDiv>
    );
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  } else {
    if (isDataReady) {
      return (
        <>
          <MiniMapBox />
          <ViewerNav />
          <StyledMapButton
            css={`
              background: none;
              border: none;
              position: fixed;
              left: ${mapButtonCSS.left};
              bottom: ${mapButtonCSS.bottom};
              z-index: 2;
            `}
          >
            {expandedMap ? (
              <Img
                onClick={collapseMap}
                width={mapButtonCSS.width}
                src={Minimize}
              />
            ) : (
              <Img
                onClick={expandMap}
                width={mapButtonCSS.width}
                src={Maximize}
              />
            )}
          </StyledMapButton>
          <div className="viewer-panorama">
            {view === "panorama" && (
              <PanoramaViewer
                url={`${CDN_URL}/${scanSessionFolder}/360/${scanSessionObject.scan_index}/`}
                sensorScanId={sensorScanId}
              />
            )}
          </div>
          <div className="viewer-pointcloud">
            {view === "lidar" && (
              <PointCloudViewer
                url={`${CDN_URL}/${scanSessionFolder}/pointclouds`}
                num_pc={pointCloudCount}
                ref={potreeChildRef}
              />
            )}
          </div>
        </>
      );
    } else {
      return (
        <Background>
          <div className="loader" style={{ top: "45%", left: "47%" }}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Background>
      );
    }
  }
}
