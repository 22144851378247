import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

export const CustomToolTip = (props: any) => {
    
  return (
    <>
        <OverlayTrigger
          placement={props.direction}
          overlay={
            <Tooltip id={`tooltip-${props.ttId}`}>
              <strong>{props.content}</strong>
            </Tooltip>
          }
        >
          {props.children}
        </OverlayTrigger>
    </>
  );
}
