

export default function Pong() {
  return (

      <div style={{ position: "fixed", left: "45%", top: "10%" }}>
      <h1 style={{"color": "#1CB797", "fontSize": "80px"}}>Pong</h1>
      </div>
  );
}
