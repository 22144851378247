import React from "react";

import Projects from "./Projects";
import Sidebar from "../../components/Sidebar/Sidebar";
import { Background } from "../public/styled";


export default function layout(props: any) {
    return (
        <Background css="min-width: 1200px">
            <Sidebar tokenStatus={props.tokenStatus} />
            <Projects />
        </Background>
    )
}
