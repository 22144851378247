import React from 'react';
import DashboardIcon from "../../assets/svg/DashboardIcon.svg"
import ContactIcon from "../../assets/svg/ContactIcon.svg"
import ProfileIcon from "../../assets/svg/ProfileIcon.svg"
import DashboardIconActive from "../../assets/svg/DashboardIconActive.svg"
import ContactIconActive from "../../assets/svg/ContactIconActive.svg"
import ProfileIconActive from "../../assets/svg/ProfileIconActive.svg"


export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/',
    icon: <img src={DashboardIcon} alt='Home' />,
    cName: 'nav-text',
    activeIcon: <img src={DashboardIconActive} alt='Home' />,
    target: "",
    isExternalPath: false,
  },
  {
    title: 'Contact',
    path: 'https://www.atritec.se/kontakt',
    icon: <img src={ContactIcon} alt='Contact' />,
    cName: 'nav-text',
    activeIcon: <img src={ContactIconActive} alt='Contact' />,
    target: "_blank",
    isExternalPath: true,
  },
  {
    title: 'Profile',
    path: '/',
    icon: <img src={ProfileIcon} alt='Profile' />,
    cName: 'nav-text',
    activeIcon: <img src={ProfileIconActive} alt='Profile' />,
    target: "",
    isExternalPath: false,
  }
];
