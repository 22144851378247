import React, { useRef, useEffect } from "react";
import { useViewer } from "../../viewerContext";

let PSV = window.PhotoSphereViewer;

const API_URL = process.env.REACT_APP_API_URL;

const swapLatLong = (position) => {
  const long = position[1];
  const lat = position[0];
  return [long, lat];
};

export default function PanoramaViewer({ url, sensorScanId }) {
  const { viewerState, setViewerState } = useViewer();

  const panoContainerDiv = useRef("pano");
  const panoViewer = useRef(null);
  const panoTour = useRef(null);
  const nodeRef = useRef();

  const getFunc = async (nodeId) => {
    const response = await fetch(
      `${API_URL}/sensorscans/${sensorScanId}/${nodeId}?save_format=jpg`
    );

    var data = await response.json();

    data.panorama = `${url}${nodeId}.jpg`;

    /*
    const prevNextLen = 2;

    //we need to swap latitude and longitude positions due to local coord specification of the photosphere library.
    data.position = swapLatLong(data.position);
    for (let i = 0; i < data.links.length; i++) {
      data.links[i].position = swapLatLong(data.links[i].position);
    }

    if (data.links.length === prevNextLen) {
      //nodes need to be swapped to render consistenly when the direction.
      const pos0 = data.links[0].position;
      const pos1 = data.links[1].position;
      data.links[0].position = pos1;
      data.links[1].position = pos0;
    }
    */
    return data;
  };

  const handlePanoArrowClick = (node_id, node_data) => {
    nodeRef.current = node_id;
    if (node_id !== viewerState.panoId) {
      setViewerState({
        type: "UPDATE_FROM_PANORAMA",
        payload: { id: nodeRef.current },
      });
    }
  };

  useEffect(() => {
    if (panoViewer.current) return;

    /**
     * Reference Material
     * https://developers.google.com/streetview/spherical-metadata
     * https://photo-sphere-viewer.js.org/guide/config.html#advanced-options
     */

    panoViewer.current = new PSV.Viewer({
      container: panoContainerDiv.current,
      requestHeaders: {},
      useXmpData: true,
      defaultZoomLvl: 30,
      autorotateSpeed: "5rpm",
      minFov: 10,
      maxFov: 100,
      defaultLong: 0.0,
      defaultLat: 0.0,
      navbar: ["autorotate", "zoom", "move", "caption", "fullscreen"],
      sphereCorrection: {
        pan: 0,
        tilt: 0,
        roll: 0
      },
      plugins: [
        PSV.MarkersPlugin,
        [
          PSV.VirtualTourPlugin,
          {
            positionMode: PSV.VirtualTourPlugin.MODE_GPS,
            dataMode: PSV.VirtualTourPlugin.MODE_SERVER,
            startNodeId: viewerState.panoId,
            preload: true,
            rotateSpeed: false,
            transition: false,
            arrowPosition: "bottom",
            getNode: getFunc,
            arrowStyle: {
              color: 0xffffff,
              hoverColor: 0xaa5500,
              outlineColor: 0x000000,
              scale: [0.5, 0.75],
            },
          },
        ],
      ],
    });

    panoTour.current = panoViewer.current.getPlugin(PSV.VirtualTourPlugin);

    panoTour.current.on("node-changed", (e, node_id_of_arrow, node_data) =>
      handlePanoArrowClick(node_id_of_arrow, node_data)
    );

    panoTour.current.setCurrentNode(viewerState.panoId);
  }, []);

  useEffect(() => {
    panoTour.current.setCurrentNode(viewerState.panoId);
  }, [viewerState.panoId])

  return (
    <div id="psv-root" className={"psv_container"}>
      <div
        id="psv_render_area"
        ref={panoContainerDiv}
        style={{ height: "100vh" }}
      />
    </div>
  );
}
